import axios from 'axios'
import {createSearchParams} from '../../utils/main'
import { Base_url} from '../../config'

//获取角色列表
function getRoleList(roleName,page){
    let currentPage = page || 1;
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/getRoleListForPage',
            params:{
                roleName:roleName,
                currentPage
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

//保存角色
function saveRole(data){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/saveRole',
            data: createSearchParams(data)
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//删除角色
function deleteRole(roleId){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/deleteRole',
            params:{
                roleId:roleId,
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

//查询角色及其配置的权限
function getRolePermissionList(roleId){
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/getRolePermissionList',
            params:{
                roleId:roleId
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

//添加角色权限
function saveRolePermission(roleId,permissionId){
    return new Promise(resolve => {
        axios({
            method: 'POST',
            url: Base_url+'/admin/save_role_permission_batch',
            params:{
                roleId:roleId,
                permissionId:permissionId
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}


//获取角色类别
// function getRoleTypelList(typeCode){
//     return new Promise(resolve => {
//         axios({
//             method: 'get',
//             url: Base_url+'/admin/getRoleTypelList/'+typeCode+'/'+new Date(),
//         }).then((res)=>{
//             resolve(res.data)
//         })
//     })
// }



//删除角色权限
function removeRolePermission(roleId,permissionId){
    return new Promise(resolve => {
        axios({
            method: 'POST',
            url: Base_url+'/admin/remove_role_permission_batch',
            params:{
                roleId:roleId,
                rolePermissionId:permissionId
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
export {
    getRoleList,
    saveRole,
    deleteRole,
    getRolePermissionList,
    saveRolePermission,
    removeRolePermission,

}
