<template>
    <el-dialog title="新增" :visible.sync="addVisible" :before-close="closeEvent" width="600px">
      <el-form ref="form" label-width="100px">
         <el-form-item size="small" label="角色名称:" required >
          <el-input v-model="roleName" maxlength="6" style="width: 435px" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="saveRole">确 定</el-button>
        <el-button size="small" @click="closeEvent">取 消</el-button>
      </span>
    </el-dialog>
</template>
<script>
  import {saveRole} from '../../../api/sys/role'

  export default {
    data () {
      return {
        roleName: '',
          roleType: ''
      }
    },
    props: {
      addVisible: Boolean,
        allRoleType: Array
    },
    methods: {
      closeEvent () {
        this.$emit('closeInsert')
      },
      async saveRole () {
        let data = {
            roleName: this.roleName,
            roleType:this.roleType
        };
        let res = await saveRole(data);
        if (res.code==200){
            this.$message({
              showClose: true,
              message: res.message,
              type: 'success'
            });
            this.roleName = '';
            this.$emit('addSuccess')
        }else {
            this.$message({
                showClose: true,
                message: res.message,
                type: 'error'
            });
        }
      }
    }
  }
</script>

<style scoped>

</style>
