<template>
     <el-dialog title="编辑" :visible.sync="editVisible" width="600px" :before-close="closeEvent">
       <el-form ref="form" label-width="100px">
         <el-form-item size="small" label="角色名称:" required >
           <el-input v-model="item.roleName" maxlength="6" style="width: 435px" show-word-limit></el-input>
         </el-form-item>
       </el-form>
       <span slot="footer" class="dialog-footer">
         <el-button size="small" type="primary" @click="saveRole">确 定</el-button>
         <el-button size="small" @click="closeEvent">取 消</el-button>
       </span>
     </el-dialog>
 </template>
<script>
  import {saveRole} from '../../../api/sys/role'

  export default {
    data () {
      return {
        show: false,
        item: {}
      }
    },

    props: {
      editItem: Object,
      editVisible: Boolean,
        allRoleType:Array
    },
     watch: {
        editItem (value) {
            this.item = value;
        }
        },
    methods: {
      closeEvent () {
        this.$emit('closeEdit')
      },
      async saveRole () {
        if (!this.item.roleName) {
            return;
        }
        let data = {
            id: this.item.id,
            roleName: this.item.roleName,
            roleType: this.item.roleType
        };
        let res = await saveRole(data);
        if (res.code==200){
            this.$message({
              showClose: true,
              message: res.message,
              type: 'success'
            });
            this.$emit('editSuccess')
        }else{
            this.$message({
              showClose: true,
              message: res.message,
              type: 'error'
            });
        }
      }
    }
  }
</script>

<style scoped>

</style>
