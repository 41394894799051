<template>
  <div>
    <div class="e-breadcrumb">角色管理</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-handle-box" style="margin-bottom: 50px">
            <div class="handle-box">
              <span class="v-form-label">角色名称:</span>
              <el-input placeholder="请输入角色名称" size="small" style="width:265px" v-model="roleName" clearable
                        class="handle-input mr10"></el-input>
            </div>
            <div style="margin-right: 37px">
              <el-row type="flex" justify="end">
                <el-button size="small" class="e-button" type="primary" @click="searchEvent">搜索</el-button>
                <el-button size="small" class="e-button" type="primary"  @click="addEvent">新增</el-button>
              </el-row>
            </div>
          </div>

          <div class="e-table-wrapper">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th width="130" class="th-l">角色名称</th>
                <th width="180" class="th-l">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l">{{row.roleName}}</td>
                <td class="td-l">
                  <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑</el-button>
                  <el-button
                      type="text"
                      icon="el-icon-delete"
                      class="red"
                      @click="removeEvent(index)"
                  >删除
                  </el-button>
                  <el-button type="text" icon="el-icon-setting" @click="openSetView(index)">设置权限</el-button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                  background
                  @current-change="handleCurrentChange"
                  :pager-count="7"
                  layout="prev, pager, next,total"
                  :total="pagination.count"
                  :page-count="pagination.pages"
                  :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Insert :addVisible="addVisible" @closeInsert="closeInsert" @addSuccess="addSuccess"  :allRoleType="allRoleType"/>
    <Edit
        :editVisible="editVisible"
        @closeEdit="closeEdit"
        @editSuccess="editSuccess"
        :editItem="editItem"
        :allRoleType="allRoleType"
    />

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="delRole">确 定</el-button>
        <el-button size="small" @click="delVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <SetPermissions
        v-if="setVisible"
        :setVisible="setVisible"
        @closeSet="closeSetView"
        :permissions="permissions"
        :roleId="roleId"
    />
  </div>
</template>

<script>
import Insert from "./insert";
import Edit from "./edit";
import SetPermissions from "./set-permissions";
import { getRoleList, deleteRole,getRoleTypelList} from "../../../api/sys/role";
export default {
  data() {
    return {
        tableLoading:false,
      roleName: "",
      stateItem: [
        {
          label: "启用",
          value: "启用"
        },
        {
          label: "禁用",
          value: "禁用"
        }
      ],
      role: "",
      roleId: "",
      permissions: [],
      list: [],
      pagination: {
        count: 0,
        pageNumber: 0,
        pageSize: 0,
        pages: 1
      },
      addVisible: false,
      editVisible: false,
      delVisible: false,
      setVisible: false,
        allRoleType:[],
      editItem: {}
    };
  },
  async created() {
    this.searchEvent();
    // this.getAllRoleType();
  },
  methods: {
    handleCurrentChange(page) {
      this.requestSearch(page);
    },
      getTypeName(typeName){
          if(typeName=="01"){
              return "前端Admin"
          }else if(typeName=="02"){
              return "平台后端"
          }else if(typeName=="03"){
              return "前端买家"
          }else if(typeName=="04"){
              return "前端卖家"
          }else if(typeName=="05"){
              return "前端卖家"
          }

      },
      //获取所有角色类别
      // async getAllRoleType() {
      //     let res = await getRoleTypelList("2ADE428D1F9C4D7C9208443E2923B73E")
      //     res.forEach(item=>{
      //         this.allRoleType.push({
      //         label: item.name,
      //         value: item.dictValue
      //     })
      // })
      // },


    //查询角色列表
    searchEvent() {
      this.requestSearch();
    },
    //分页
    async requestSearch(page) {
          this.tableLoading=true;
      let dataSet = await getRoleList(this.roleName, page);
      let res = dataSet.dataSet
      this.list = res.list;
      this.pagination = {
        count: res.count,
        pageNumber: res.pageNumber,
        pageSize: res.pageSize,
        pages: res.pages
      };
          this.tableLoading=false;
    },
    //添加角色
    addEvent() {
      this.addVisible = true;
    },
    //删除角色
    async delRole() {
      let res = await deleteRole(this.editItem.id);
      if (res.code==200) {
        this.searchEvent();
        this.$message({
          showClose: true,
          message: res.message,
          type: "success"
        });
        this.delVisible = false;
      } else {
        this.$message({
          showClose: true,
          message: res.message,
          type: "error"
        });
      }
    },

    //编辑角色
    editEvent(index) {
      this.editItem = this.list[index];
      this.editVisible = true;
    },
    addSuccess() {
      this.closeInsert();
      this.searchEvent();
    },

    closeInsert() {
      this.addVisible = false;
    },
    editSuccess() {
      this.closeEdit();
      this.searchEvent();
    },
    closeEdit() {
      this.editVisible = false;
    },
    openSetView(index) {
      this.roleId = this.list[index].id;
      this.setVisible = true;
    },
    closeSetView() {
      this.setVisible = false;
    },
    removeEvent(index) {
      this.editItem = this.list[index];
      this.delVisible = true;
    }
  },
  components: {
    Insert,
    Edit,
    SetPermissions
  }
};
</script>

<style scoped>
  .th-l{
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }
  .td-l{
    padding: 10px 12px;
    display:table-cell;
    text-align: center;
    vertical-align:middle;
  }
  .off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}

</style>
